
import { defineComponent, onMounted, reactive, toRaw } from 'vue'

import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'

import iClose from '@/assets/icons/Close.svg'

import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { useMaska } from '@/common/composable/useMaska'

export default defineComponent({
    components: {
        's-input': SInput,
        's-button': SButton,
        'icon-close': iClose,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        phone: {
            type: String,
            default: null,
        },
        email: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['confirm', 'close'],
    setup(props, { emit }) {
        const state = reactive({
            name: null as string | null,
            email: null as string | null,
            phone: null as string | null,
        })

        const v$ = useVuelidate(
            {
                name: { required },
                email: { required, email },
                phone: { required, minLength: minLength(18) },
            },
            state
        )

        const initializeState = () => {
            state.name = props.name
            state.phone = props.phone
            state.email = props.email

            v$.value.$reset()
        }

        const emitClose = () => {
            emit('close')
        }

        const { unmaskNumbers } = useMaska()

        const emitConfirm = () => {
            v$.value.$touch()
            if (v$.value.$invalid) return

            const payload = toRaw({ ...state })
            if (payload.phone) payload.phone = '+' + unmaskNumbers(payload.phone)

            emit('confirm', payload)
        }

        onMounted(initializeState)
        return { state, v$, emitClose, emitConfirm }
    },
})
