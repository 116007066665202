
import { defineComponent, onMounted, provide, reactive, ref } from 'vue'

import ProfileCard from '@/modules/Profile/components/ProfileCard/index.vue'
import ProfileCompanyAndContacts from '@/modules/Profile/components/ProfileCompanyAndContacts/index.vue'

import SPassword from '@/common/components/SPassword/index.vue'
import SButton from '@/common/components/SButton/index.vue'

import SModal from '@/common/components/SModal/index.vue'
import ProfileCardForm from '@/modules/Profile/components/ProfileCardForm/index.vue'

import iClose from '@/assets/icons/Close.svg'

import { useModal } from '@/common/composable/useModal'
import { useAuthorization } from '@/common/composable/useAuthorization'
import { useRouter } from 'vue-router'

import { useProfile } from '@/modules/Profile/composable/useProfile'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { PasswordPayloadT, ProfileCardPayload } from '@/core/types/Users.types'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

import { useTitle } from 'vue-composable'
import useAccount from '@/common/composable/useAccount'

export default defineComponent({
    components: {
        'profile-card': ProfileCard,
        'profile-company-and-contacts': ProfileCompanyAndContacts,
        'profile-card-form': ProfileCardForm,
        's-password': SPassword,
        's-button': SButton,
        'icon-close': iClose,
        's-modal': SModal,
    },
    setup() {
        useTitle('Профиль')
        const notifications = useNotifications()
        const account = useAccount()

        const { profileState, loadProfile, changePassword, updateProfile } = useProfile()

        const loadProfileHandler = async () => {
            try {
                loadProfile()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения профиля',
                    },
                    error
                )
            }
        }

        onMounted(loadProfileHandler)

        provide('profileState', profileState)

        const isProfileModalOpened = ref(false)
        const { openModal, closeModal } = useModal(isProfileModalOpened)

        const { authorizationUnauthorize } = useAuthorization()
        const router = useRouter()

        const profileModalState = reactive({
            old_password: null as string | null,
            new_password: null as string | null,
            re_password: null as string | null,
        })

        const ChangePasswordValidation = {
            old_password: { required },
            new_password: { required },
            re_password: {
                required,
                sameAs: (value: string) => {
                    if (value === profileModalState.new_password) return true
                    return false
                },
            },
        }

        const v$ = useVuelidate(ChangePasswordValidation, profileModalState)

        const resetPasswordModal = () => {
            profileModalState.old_password = null
            profileModalState.new_password = null
            profileModalState.re_password = null
            v$.value.$reset()
        }

        const isChangePasswordLoading = ref(false)

        const initPasswordModal = () => {
            resetPasswordModal()
            openModal()
        }

        const changePasswordHandler = async () => {
            v$.value.$touch()
            if (v$.value.$invalid) return

            try {
                isChangePasswordLoading.value = true

                const payload: PasswordPayloadT = {
                    password: profileModalState.new_password as string,
                    old_password: profileModalState.old_password as string,
                    password_confirmation: profileModalState.re_password as string,
                }

                await changePassword(payload)

                closeModal()
                resetPasswordModal()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка смены пароля',
                    },
                    error
                )
            } finally {
                isChangePasswordLoading.value = false
            }
        }

        const logoutHandler = () => {
            authorizationUnauthorize()
            account.reset()
            router.replace({ name: 'Auth' })
        }

        const isEditing = ref(false)
        const isEditingLoading = ref(false)
        const editProfileModal = useModal(isEditing)

        const updateProfileHandler = async (payload: ProfileCardPayload) => {
            try {
                isEditingLoading.value = true
                await updateProfile(payload)
                editProfileModal.closeModal()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка редактирования профиля',
                    },
                    error
                )
            } finally {
                isEditingLoading.value = false
            }
        }

        return {
            isProfileModalOpened,
            closeModal,
            initPasswordModal,
            profileModalState,
            changePasswordHandler,
            logoutHandler,
            profileState,
            v$,
            isChangePasswordLoading,
            isEditing,
            editProfileModal,
            isEditingLoading,
            updateProfileHandler,
        }
    },
})
