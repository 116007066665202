
import { defineComponent, inject } from 'vue'
import SButton from '@/common/components/SButton/index.vue'

import { USERS_TOKENS } from '@/core/constants/common.constants'
import useAccount from '@/common/composable/useAccount'

export default defineComponent({
    components: {
        's-button': SButton,
    },
    setup() {
        const { havePermission } = useAccount()
        const profileState = inject('profileState')

        return { profileState, USERS_TOKENS, havePermission }
    },
})
