
import { defineComponent } from 'vue'

import Company from '@/modules/Profile/components/Company/index.vue'
import Contacts from '@/modules/Profile/components/Contacts/index.vue'
import Users from '@/modules/Profile/components/Users/index.vue'

import SLoader from '@/common/components/SLoader/index.vue'

export default defineComponent({
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        company: Company,
        contacts: Contacts,
        users: Users,
        's-loader': SLoader,
    },
})
