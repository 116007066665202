
import { defineComponent, PropType } from 'vue'

import SButton from '@/common/components/SButton/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'

import iEdit from '@/assets/icons/Edit.svg'
import { ProfileResponseT } from '@/core/types/Responses/Users.responses'

export default defineComponent({
    components: {
        's-button': SButton,
        's-loader': SLoader,
        'icon-edit': iEdit,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        card: {
            type: Object as PropType<ProfileResponseT>,
            default: null,
        },
    },
    emits: ['edit', 'change-password', 'logout'],
    setup(_, { emit }) {
        const emitEdit = () => {
            emit('edit')
        }

        const emitChangePassword = () => {
            emit('change-password')
        }

        const emitLogout = () => {
            emit('logout')
        }

        return { emitEdit, emitChangePassword, emitLogout }
    },
})
