import { UsersRealisation } from '@/core/realisations/Users.realisation'
import { ProfileResponseT } from '@/core/types/Responses/Users.responses'
import { PasswordPayloadT, ProfileCardPayload } from '@/core/types/Users.types'

import { reactive } from 'vue'

export const useProfile = () => {
    const profileState = reactive({
        state: {
            is_loading: false,
        },
        data: null as ProfileResponseT | null,
    })

    const loadProfile = async () => {
        try {
            profileState.state.is_loading = true
            const usersRealisation = new UsersRealisation()
            const profileResponse = await usersRealisation.getProfile()

            profileState.data = profileResponse.data
        } catch (error) {
            console.error(error)
        } finally {
            profileState.state.is_loading = false
        }
    }

    const changePassword = async (payload: PasswordPayloadT) => {
        const usersRealisation = new UsersRealisation()
        await usersRealisation.changePassword(payload)
    }

    const updateProfile = async (payload: ProfileCardPayload) => {
        const usersRealisation = new UsersRealisation()
        await usersRealisation.updateProfile(payload)

        if (!profileState.data) return
        profileState.data.email = payload.email
        profileState.data.name = payload.name
        profileState.data.phone = payload.phone
    }

    return { profileState, loadProfile, changePassword, updateProfile }
}
